import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { orgsKeys } from "src/data/organizations/queries/organizationQueryCache"
import { IOrganizationMember } from "src/data/organizations/types/organizationMemberTypes"
import {
  IFetchMembers,
  IMembersFilter,
} from "src/data/organizations/types/organizationTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchMemberSelf({
  orgId,
  options,
}: {
  orgId: string
  options?: UseQueryOptions<
    IOrganizationMember,
    AxiosError,
    IOrganizationMember,
    ReturnType<typeof orgsKeys.membersList>
  >
}) {
  /**
   * Returns a list of an organizations members
   *
   * https://api.staging.minut.com/latest/docs/internal#tag/Organization-Members/paths/~1organizations~1{organization_id}~1members/get
   */
  async function fetchMemberSelf({ orgId }: { orgId: string }) {
    const response: AxiosResponse<{
      members: IOrganizationMember[]
    }> = await minutApiHttpClient.get(
      `${API_DEFAULT}/organizations/${orgId}/members?filter=self`
    )
    if (!response.data.members[0]) {
      throw new Error("Member not found")
    }
    // TODO WEB-428
    return response.data.members[0]
  }

  return useQuery(
    orgsKeys.membersList(orgId, { filter: ["self"] }),
    () => fetchMemberSelf({ orgId }),
    {
      keepPreviousData: true,
      ...options,
    }
  )
}

export function useFetchMembers<TQueryFnData = IFetchMembers>({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter?: IMembersFilter
  options?: UseQueryOptions<
    IFetchMembers,
    AxiosError,
    TQueryFnData,
    ReturnType<typeof orgsKeys.membersList>
  >
}) {
  async function fetchMembers(): Promise<IFetchMembers> {
    const response = await minutApiHttpClient.get<IFetchMembers>(
      `${API_DEFAULT}/organizations/${orgId}/members`,
      { params: filter }
    )
    return response.data
  }

  return useQuery(orgsKeys.membersList(orgId, filter), () => fetchMembers(), {
    keepPreviousData: true,
    ...options,
  })
}

export function useFetchMemberCount({
  orgId,
  filter,
  options,
}: {
  orgId: string
  filter?: IMembersFilter
  options?: UseQueryOptions<
    IFetchMembers,
    AxiosError,
    number,
    ReturnType<typeof orgsKeys.membersList>
  >
}) {
  return useFetchMembers({
    orgId,
    filter: { ...filter, limit: 1 },
    options: {
      ...options,
      select: (data) => data.paging.total_count,
    },
  })
}

// Update member
// https://api.staging.minut.com/latest/docs/internal#tag/Organization-Members/paths/~1organizations~1{organization_id}~1members~1{organization_member_id}/patch
export function usePatchMember() {
  const cache = useQueryClient()

  async function patchMember({
    orgId,
    memberId,
    body,
  }: {
    orgId: string
    memberId: string
    body: Partial<IOrganizationMember>
  }) {
    const response = await minutApiHttpClient.patch<IOrganizationMember>(
      `${API_DEFAULT}/organizations/${orgId}/members/${memberId}`,
      body
    )
    return response
  }

  return useMutation(patchMember, {
    onSuccess: async (response, { orgId }) => {
      const patchedMember = response.data

      // TODO WEB-428: Currently the cache keeps both IOrganizationMember and an
      // IOrganizationMember[] array; we should look into simplifying this
      // structure at a later time
      cache.setQueriesData<
        IOrganizationMember[] | IOrganizationMember | undefined
      >(orgsKeys.membersList(orgId), (memberCache) => {
        if (!memberCache) return undefined
        // Handle type IOrganizationMember
        if (!(memberCache instanceof Array)) return memberCache

        // Handle type IOrganizationMember[]
        return (memberCache ?? []).map((member) => {
          if (member.member_id === patchedMember.member_id) {
            return patchedMember
          }
          return member
        })
      })

      // The backend has a bug where patching a member role does not include the
      // updated role in the returned member object. For now we can force a
      // refetch to workaround this, but remove once the backend returns a
      // properly updated object.
      await cache.invalidateQueries(orgsKeys.membersList(orgId))
    },
  })
}

// Remove member
// https://api.staging.minut.com/latest/docs/internal#tag/Organization-Members/paths/~1organizations~1{organization_id}~1members~1{organization_member_id}/delete
export function useDeleteMember() {
  const cache = useQueryClient()

  async function deleteMember({
    orgId,
    memberId,
  }: {
    orgId: string
    memberId: string
  }) {
    const response = await minutApiHttpClient.delete(
      `${API_DEFAULT}/organizations/${orgId}/members/${memberId}`
    )
    const rule: IOrganizationMember = response.data
    return rule
  }

  return useMutation(deleteMember, {
    onSuccess: async (response, { orgId, memberId }) => {
      cache.invalidateQueries(orgsKeys.membersList(orgId))
      // TODO WEB-428: Currently the cache keeps both IOrganizationMember and an
      // IOrganizationMember[] array; we should look into simplifying this
      // structure at a later time
      cache.setQueriesData<
        IOrganizationMember[] | IOrganizationMember | undefined
      >(orgsKeys.membersList(orgId), (memberCache) => {
        if (!memberCache) return undefined
        if (!(memberCache instanceof Array)) return memberCache

        if (memberCache instanceof Array) {
          return (
            memberCache?.filter((member) => member.member_id !== memberId) ?? []
          )
        }
      })

      // If the user removes themselves from the organization, we need to remove
      // the org from their cache
      return await Promise.all([
        cache.invalidateQueries(orgsKeys.orgLists()),
        cache.invalidateQueries(orgsKeys.org(orgId)),
        cache.invalidateQueries(orgsKeys.activeOrg()),
      ])
    },
  })
}
